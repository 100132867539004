
import { CodeRepoLink, CodeRepoType, ModelObject, PermissionType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import openExternalLink from '@/helpers/open-external-link'
import { iconUrlForTheme } from '@/helpers/theme'
import { DiagramModule } from '@/modules/diagram/store'
import { LandscapeModule } from '@/modules/landscape/store'
import * as modelAnalytics from '@/modules/model/helpers/analytics'
import { ModelModule } from '@/modules/model/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import * as icons from '../helpers/icons'
import * as resolveLink from '../helpers/resolve-link'

@Component({
  name: 'CodeLinksList'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly permission!: PermissionType
  @Prop() readonly objects!: ModelObject[]

  defaultSection = 'invalid'
  selectedIndex = 0

  fileIcons = icons.files
  linkIcons = icons.links

  iconUrlForTheme = iconUrlForTheme

  get currentLandscapeId () {
    return this.$params.landcapeId || this.currentVersion?.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagramsCache || this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)!
  }

  get validObjectLinks () {
    return this.objects
      .filter(o => o.type !== 'root')
      .reduce<({ link: CodeRepoLink, object: ModelObject, type: CodeRepoType })[]>((p, c) => [
        ...p,
        ...Object
          .values(c.links)
          .filter((l): l is CodeRepoLink => 'status' in l && l.status === 'valid')
          .map(l => ({
            link: l,
            object: c,
            to: {
              name: 'model-objects',
              params: {
                landscapeId: this.currentLandscapeId,
                versionId: this.currentVersionId
              },
              query: this.$setQuery({
                accuracy_menu: undefined,
                focus: c.handleId,
                object: c.handleId
              })
            },
            type: resolveLink.type(l.type)
          }))
      ], [])
  }

  get invalidObjectLinks () {
    return this.objects
      .filter(o => o.type !== 'root')
      .reduce<({ link: CodeRepoLink, object: ModelObject, type: CodeRepoType, extension?: string })[]>((p, c) => [
        ...p,
        ...Object
          .values(c.links)
          .filter((l): l is CodeRepoLink => 'status' in l && l.status === 'invalid')
          .map(l => ({
            extension: 'path' in l ? l.path.split('.').pop() : undefined,
            link: l,
            object: c,
            to: {
              name: 'model-objects',
              params: {
                landscapeId: this.currentLandscapeId,
                versionId: this.currentVersionId
              },
              query: this.$setQuery({
                accuracy_menu: undefined,
                focus: c.handleId,
                object: c.handleId
              })
            },
            type: resolveLink.type(l.type)
          }))
      ], [])
  }

  get unlinkedObjectLinks () {
    return this.objects
      .filter(o => o.type !== 'root' && Object.values(o.links).every(l => !('status' in l)))
      .map(o => ({
        object: o,
        to: {
          name: 'model-objects',
          params: {
            landscapeId: this.currentLandscapeId,
            versionId: this.currentVersionId
          },
          query: this.$setQuery({
            accuracy_menu: undefined,
            focus: o.handleId,
            object: o.handleId
          })
        }
      }))
  }

  get codeRepoTypes (): Record<CodeRepoType, string> {
    return {
      'azure-devops': 'Azure DevOps',
      bitbucket: 'Bitbucket',
      'bitbucket-server': 'Bitbucket server',
      github: 'GitHub',
      gitlab: 'GitLab'
    }
  }

  get sections () {
    return [
      {
        icon: '$fas-exclamation-triangle',
        iconColor: this.invalidObjectLinks.length ? 'error' : 'grey lighten-4',
        id: 'invalid',
        items: this.invalidObjectLinks || [],
        title: 'Outdated reality links'
      },
      {
        id: 'valid',
        image: require('../assets/link-valid.png'),
        items: this.validObjectLinks || [],
        title: 'Active links to reality'
      },
      {
        id: 'unlinked',
        image: require('../assets/unlinked.png'),
        items: this.unlinkedObjectLinks || [],
        title: 'Not linked to reality'
      }
    ]
  }

  created () {
    const lastSection = localStorage.getItem('accuracyMenuLinksSectionKey')
    if (lastSection && this.sections.some(o => o.id === lastSection)) {
      this.defaultSection = lastSection
    }
    this.selectedIndex = this.sections.findIndex(o => o.id === this.defaultSection)
  }

  saveSection (id: string) {
    this.defaultSection = id
    localStorage.setItem('accuracyMenuLinksSectionKey', id)
  }

  openLink (link: CodeRepoLink) {
    openExternalLink(link.url)

    modelAnalytics.modelObjectLinkOpen.track(this, {
      landscapeId: [this.currentLandscape.id],
      modelObjectLinkType: link.type,
      organizationId: [this.currentLandscape.organizationId]
    })
  }
}
