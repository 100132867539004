import { CatalogTechnologiesService, CatalogTechnology, CatalogTechnologyFilter, CatalogTechnologyType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

export interface ICatalogModule {
  technologies: Record<string, CatalogTechnology>
  technologiesFetched: boolean
}

const name = 'catalog'

@Module({
  name,
  namespaced: true
})
export class CatalogModule extends VuexModule implements ICatalogModule {
  static namespace = name

  technologies: Record<string, CatalogTechnology> = {}
  technologiesFetched = false

  technologyGroups: Record<string, 'all' | 'cloud' | CatalogTechnologyType> = {
    all: 'all',
    kubernetes: 'kubernetes',
    language: 'language',
    library: 'library',
    'operating-system': 'operating-system',
    service: 'service',
    tool: 'tool'
  }

  @Mutation
  setTechnology (technology: CatalogTechnology) {
    Vue.set(this.technologies, technology.id, technology)
  }

  @Mutation
  setTechnologies (technologies: CatalogTechnology[]) {
    Object.values(technologies).forEach(o => {
      Vue.set(this.technologies, o.id, o)
    })
  }

  @Mutation
  resetTechnologies () {
    this.technologies = {}
  }

  @Mutation
  setTechnologiesFetched (technologiesFetched: boolean) {
    this.technologiesFetched = technologiesFetched
  }

  @Action({ rawError: true })
  async catalogTechnologiesList (filter: CatalogTechnologyFilter) {
    const { catalogTechnologies } = await CatalogTechnologiesService.catalogTechnologiesList(filter)
    this.context.commit('setTechnologies', catalogTechnologies)
    if (!Object.keys(filter).length) {
      this.context.commit('setTechnologiesFetched', true)
    }
    return {
      catalogTechnologies
    }
  }

  @Action({ rawError: true })
  async catalogTechnologyFind (id: string) {
    const { catalogTechnology } = await CatalogTechnologiesService.catalogTechnologyFind(id)
    this.context.commit('setTechnology', catalogTechnology)
    return {
      catalogTechnology
    }
  }
}
