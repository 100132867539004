import { CodeRepo, CodeRepoLinkAzureDevops, CodeRepoLinkBitbucket, CodeRepoLinkBitbucketServer, CodeRepoLinkGithub, CodeRepoLinkGitlab, CodeRepoLinkType, CodeRepoType } from '@icepanel/platform-api-client'

export const name = (codeRepo: CodeRepo, link: CodeRepoLinkAzureDevops | CodeRepoLinkBitbucket | CodeRepoLinkBitbucketServer | CodeRepoLinkGithub | CodeRepoLinkGitlab) => {
  switch (link.type) {
    case 'azure-devops-folder': return `${codeRepo.name}/${link.branchName}/${link.path}`
    case 'azure-devops-file': return `${codeRepo.name}/${link.branchName}/${link.path}`
    case 'azure-devops-branch': return `${codeRepo.name}/${link.branchName}`
    case 'azure-devops-repo': return codeRepo.name

    case 'bitbucket-folder': return `${codeRepo.name}/${link.branchName}/${link.path}`
    case 'bitbucket-file': return `${codeRepo.name}/${link.branchName}/${link.path}`
    case 'bitbucket-branch': return `${codeRepo.name}/${link.branchName}`
    case 'bitbucket-repo': return codeRepo.name

    case 'bitbucket-server-folder': return `${codeRepo.name}/${link.branchName}/${link.path}`
    case 'bitbucket-server-file': return `${codeRepo.name}/${link.branchName}/${link.path}`
    case 'bitbucket-server-branch': return `${codeRepo.name}/${link.branchName}`
    case 'bitbucket-server-repo': return codeRepo.name

    case 'github-folder': return `${codeRepo.name}/${link.branchName}/${link.path}`
    case 'github-file': return `${codeRepo.name}/${link.branchName}/${link.path}`
    case 'github-branch': return `${codeRepo.name}/${link.branchName}`
    case 'github-repo': return codeRepo.name

    case 'gitlab-folder': return `${codeRepo.name}/${link.branchName}/${link.path}`
    case 'gitlab-file': return `${codeRepo.name}/${link.branchName}/${link.path}`
    case 'gitlab-branch': return `${codeRepo.name}/${link.branchName}`
    case 'gitlab-repo': return codeRepo.name
  }
}

export const url = (codeRepo: CodeRepo, link: CodeRepoLinkAzureDevops | CodeRepoLinkBitbucket | CodeRepoLinkBitbucketServer | CodeRepoLinkGithub | CodeRepoLinkGitlab) => {
  switch (link.type) {
    case 'azure-devops-folder': return `${encodeURI(codeRepo.url)}?path=${encodeURIComponent(link.path)}&version=GB${encodeURIComponent(link.branchName)}`
    case 'azure-devops-file': return `${encodeURI(codeRepo.url)}?path=${encodeURIComponent(link.path)}&version=GB${encodeURIComponent(link.branchName)}`
    case 'azure-devops-branch': return `${encodeURI(codeRepo.url)}?version=GB${encodeURIComponent(link.branchName)}`
    case 'azure-devops-repo': return encodeURI(codeRepo.url)

    case 'bitbucket-folder': return `${encodeURI(codeRepo.url)}/src/${encodeURIComponent(link.branchName)}/${encodeURIComponent(link.path)}`
    case 'bitbucket-file': return `${encodeURI(codeRepo.url)}/src/${encodeURIComponent(link.branchName)}/${encodeURIComponent(link.path)}`
    case 'bitbucket-branch': return `${encodeURI(codeRepo.url)}/src/${encodeURIComponent(link.branchName)}`
    case 'bitbucket-repo': return encodeURI(codeRepo.url)

    case 'bitbucket-server-folder': return `${encodeURI(codeRepo.url)}/${encodeURIComponent(link.path)}?at=refs%2Fheads%2F${encodeURIComponent(link.branchName)}`
    case 'bitbucket-server-file': return `${encodeURI(codeRepo.url)}/${encodeURIComponent(link.path)}?at=refs%2Fheads%2F${encodeURIComponent(link.branchName)}`
    case 'bitbucket-server-branch': return `${encodeURI(codeRepo.url)}?at=refs%2Fheads%2F${encodeURIComponent(link.branchName)}`
    case 'bitbucket-server-repo': return encodeURI(codeRepo.url)

    case 'github-folder': return `${encodeURI(codeRepo.url)}/tree/${decodeURIComponent(link.branchName)}/${encodeURIComponent(link.path)}`
    case 'github-file': return `${encodeURI(codeRepo.url)}/blob/${decodeURIComponent(link.branchName)}/${encodeURIComponent(link.path)}`
    case 'github-branch': return `${encodeURI(codeRepo.url)}/tree/${decodeURIComponent(link.branchName)}`
    case 'github-repo': return encodeURI(codeRepo.url)

    case 'gitlab-folder': return `${encodeURI(codeRepo.url)}/-/tree/${decodeURIComponent(link.branchName)}/${encodeURIComponent(link.path)}`
    case 'gitlab-file': return `${encodeURI(codeRepo.url)}/-/blob/${decodeURIComponent(link.branchName)}/${encodeURIComponent(link.path)}`
    case 'gitlab-branch': return `${encodeURI(codeRepo.url)}/-/tree/${decodeURIComponent(link.branchName)}`
    case 'gitlab-repo': return encodeURI(codeRepo.url)
  }
}

export const type = (type: CodeRepoLinkType): CodeRepoType => {
  switch (type) {
    case 'azure-devops-folder': return 'azure-devops'
    case 'azure-devops-file': return 'azure-devops'
    case 'azure-devops-branch': return 'azure-devops'
    case 'azure-devops-repo': return 'azure-devops'

    case 'bitbucket-folder': return 'bitbucket'
    case 'bitbucket-file': return 'bitbucket'
    case 'bitbucket-branch': return 'bitbucket'
    case 'bitbucket-repo': return 'bitbucket'

    case 'bitbucket-server-folder': return 'bitbucket-server'
    case 'bitbucket-server-file': return 'bitbucket-server'
    case 'bitbucket-server-branch': return 'bitbucket-server'
    case 'bitbucket-server-repo': return 'bitbucket-server'

    case 'github-folder': return 'github'
    case 'github-file': return 'github'
    case 'github-branch': return 'github'
    case 'github-repo': return 'github'

    case 'gitlab-folder': return 'gitlab'
    case 'gitlab-file': return 'gitlab'
    case 'gitlab-branch': return 'gitlab'
    case 'gitlab-repo': return 'gitlab'
  }
}

export const urlComponents = (url: string): string | null => {
  const { host, pathname, searchParams } = new window.URL(url)
  if (host === 'dev.azure.com') {
    const codeRepoUrl = pathname.slice(1).replace('/_git/', '/')
    const path = searchParams.get('path')
    const branchName = searchParams.get('version')?.replace(/^GB/g, '') || (path ? 'main' : undefined)
    if (path && branchName) {
      return `${codeRepoUrl}/${decodeURIComponent(branchName)}${decodeURIComponent(path)}`
    } else if (branchName) {
      return `${codeRepoUrl}/${decodeURIComponent(branchName)}`
    } else {
      return codeRepoUrl
    }
  } else if (host === 'bitbucket.org') {
    const codeRepoUrl = pathname.slice(1).split(/\/src\//)?.[0]
    const path = pathname.split(/\/src\//)?.[1]
    if (path) {
      return `${codeRepoUrl}/${decodeURIComponent(path)}`
    } else {
      return codeRepoUrl
    }
  } else if (host === 'github.com') {
    const codeRepoUrl = pathname.slice(1).split(/\/(tree|blob)\//)?.[0]
    const path = pathname.split(/\/(tree|blob)\//)?.[2]
    if (path) {
      return `${codeRepoUrl}/${decodeURIComponent(path)}`
    } else {
      return codeRepoUrl
    }
  } else if (host === 'gitlab.com') {
    const codeRepoUrl = pathname.slice(1).split(/\/-\/(tree|blob)\//)?.[0]
    const path = pathname.split(/\/-\/(tree|blob)\//)?.[2]
    if (path) {
      return `${codeRepoUrl}/${decodeURIComponent(path)}`
    } else {
      return codeRepoUrl
    }
  } else {
    return null
  }
}
