import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

const prefix: any = 'custom'

const crossSquare: IconDefinition = {
  icon: [448, 512, [], '', 'M400,32 L48,32 C21.5,32 0,53.5 0,80 L0,432 C0,458.5 21.5,480 48,480 L400,480 C426.5,480 448,458.5 448,432 L448,80 C448,53.5 426.5,32 400,32 Z M48,148.084 L400,420.141 L400,426 C400,429.3 397.3,432 394,432 L54,432 C50.7,432 48,429.3 48,426 L48,148.084 Z M394,80 C397.3,80 400,82.7 400,86 L400,363.513 L48,91.456 L48,86 C48,82.7 50.7,80 54,80 L394,80 Z'],
  iconName: 'cross-square' as any,
  prefix
}

const location: IconDefinition = {
  icon: [512, 512, [], 'f601', 'M496 224h-50.88C431.61 143.66 368.34 80.39 288 66.88V16c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v50.88C143.66 80.39 80.39 143.66 66.88 224H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h50.88C80.39 368.34 143.66 431.61 224 445.12V496c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16v-50.88c80.34-13.51 143.61-76.78 157.12-157.12H496c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM256 384c-70.7 0-128-57.31-128-128s57.3-128 128-128 128 57.31 128 128-57.3 128-128 128zm0-216c-48.6 0-88 39.4-88 88s39.4 88 88 88 88-39.4 88-88-39.4-88-88-88z'],
  iconName: 'location' as any,
  prefix
}

const locationSlash: IconDefinition = {
  icon: [640, 512, [], 'f603', 'M633.82 458.1L488.95 346.13c9.66-17.86 16.68-37.35 20.18-58.13H560c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16h-50.88C495.61 143.66 432.34 80.39 352 66.88V16c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v50.88c-37.52 6.31-71.24 23.54-97.95 48.24L45.47 3.37C38.49-2.05 28.43-.8 23.01 6.18L3.37 31.45C-2.05 38.42-.8 48.47 6.18 53.9l588.36 454.73c6.98 5.43 17.03 4.17 22.46-2.81l19.64-25.27c5.41-6.97 4.16-17.02-2.82-22.45zM437.33 306.24l-33.09-25.58c2.33-7.84 3.76-16.05 3.76-24.66 0-48.6-39.4-88-88-88-16.56 0-31.84 4.8-44.99 12.78l-33.21-25.66C263.45 138.28 290.44 128 320 128c70.7 0 128 57.31 128 128 0 17.93-3.94 34.83-10.67 50.24zM320 384c-62.37 0-114.18-44.65-125.55-103.7L121.61 224H80c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h50.88c13.51 80.34 76.78 143.61 157.12 157.12V496c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16v-50.88c14.07-2.37 27.35-6.75 40.19-11.99l-64.57-49.9c-2.57.15-5.01.77-7.62.77z'],
  iconName: 'location-slash' as any,
  prefix
}

export default [
  crossSquare,
  location,
  locationSlash
]
