import * as fabGithub from '@fortawesome/free-brands-svg-icons/faGithub'
import * as fabMarkdown from '@fortawesome/free-brands-svg-icons/faMarkdown'
import * as fasCaretDown from '@fortawesome/free-solid-svg-icons/faCaretDown'
import * as fasCheck from '@fortawesome/free-solid-svg-icons/faCheck'
import * as fasCheckCircle from '@fortawesome/free-solid-svg-icons/faCheckCircle'
import * as fasCodeBranch from '@fortawesome/free-solid-svg-icons/faCodeBranch'
import * as fasExclamationTriangle from '@fortawesome/free-solid-svg-icons/faExclamationTriangle'
import * as fasExternalLinkAlt from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt'
import * as fasFile from '@fortawesome/free-solid-svg-icons/faFile'
import * as fasFileAlt from '@fortawesome/free-solid-svg-icons/faFileAlt'
import * as fasFileCode from '@fortawesome/free-solid-svg-icons/faFileCode'
import * as fasFileImage from '@fortawesome/free-solid-svg-icons/faFileImage'
import * as fasFolder from '@fortawesome/free-solid-svg-icons/faFolder'
import * as fasFolderOpen from '@fortawesome/free-solid-svg-icons/faFolderOpen'
import * as fasLink from '@fortawesome/free-solid-svg-icons/faLink'
import * as fasPencilAlt from '@fortawesome/free-solid-svg-icons/faPencilAlt'
import * as fasPlusCircle from '@fortawesome/free-solid-svg-icons/faPlusCircle'
import * as fasWrench from '@fortawesome/free-solid-svg-icons/faWrench'
import { CodeRepoLinkType } from '@icepanel/platform-api-client'

export default [
  fabGithub.definition,
  fabMarkdown.definition,
  fasCaretDown.definition,
  fasCheck.definition,
  fasCheckCircle.definition,
  fasCodeBranch.definition,
  fasExclamationTriangle.definition,
  fasExternalLinkAlt.definition,
  fasFile.definition,
  fasFileAlt.definition,
  fasFileCode.definition,
  fasFileImage.definition,
  fasFolder.definition,
  fasFolderOpen.definition,
  fasLink.definition,
  fasPencilAlt.definition,
  fasPlusCircle.definition,
  fasWrench.definition
] as const

export const files = {
  css: '$fas-file-code',
  gif: '$fas-file-image',
  html: '$fas-file-code',
  ico: '$fas-file-image',
  jpeg: '$fas-file-image',
  jpg: '$fas-file-image',
  js: '$fas-file-code',
  json: '$fas-file-alt',
  lock: '$fas-file-alt',
  md: '$fab-markdown',
  png: '$fas-file-image',
  scss: '$fas-file-code',
  sh: '$fas-file-code',
  svg: '$fas-file-image',
  ts: '$fas-file-code',
  txt: '$fas-file-alt',
  vue: '$fas-file-code',
  xml: '$fas-file-code',
  yaml: '$fas-file-alt',
  yml: '$fas-file-alt'
} as Record<string, string>

const links: Record<CodeRepoLinkType | 'url', string> = {
  'azure-devops-branch': '$fas-code-branch',
  'azure-devops-file': '$fas-file',
  'azure-devops-folder': '$fas-folder',
  'azure-devops-repo': '$fab-microsoft',
  'bitbucket-branch': '$fas-code-branch',
  'bitbucket-file': '$fas-file',
  'bitbucket-folder': '$fas-folder',
  'bitbucket-repo': '$fab-bitbucket',
  'bitbucket-server-branch': '$fas-code-branch',
  'bitbucket-server-file': '$fas-file',
  'bitbucket-server-folder': '$fas-folder',
  'bitbucket-server-repo': '$fab-bitbucket',
  'github-branch': '$fas-code-branch',
  'github-file': '$fas-file',
  'github-folder': '$fas-folder',
  'github-repo': '$fab-github',
  'gitlab-branch': '$fas-code-branch',
  'gitlab-file': '$fas-file',
  'gitlab-folder': '$fas-folder',
  'gitlab-repo': '$fab-gitlab',
  url: '$fas-link'
}
export { links }
