import { CodeRepo, CodeReposService, CodeRepoTreeItem } from '@icepanel/platform-api-client'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import Status from '@/helpers/status'

export interface ICodeModule {
  codeRepos: CodeRepo[]
  codeRepoRefTree: CodeRepoTreeItem[]

  codeReposListStatus: Status<{ initial: boolean }, { organizationId: string }>
  codeReposExistStatus: Status<{ organizationId: string }, { organizationId: string, status: number }>
  codeRepoTreesSyncStatus: Status<{ organizationId: string, codeRepoId: string }, { organizationId: string, codeRepoId: string }>
  codeRepoRefTreeFindStatus: Status<{}, { organizationId: string, codeRepoId: string, refId: string }>
}

const name = 'code'

@Module({
  name,
  namespaced: true
})
export class CodeModule extends VuexModule {
  static namespace = name

  codeRepos: CodeRepo[] = []
  codeRepoRefTree: CodeRepoTreeItem[] = []

  codeReposListStatus = new Status<{ initial: boolean }, { organizationId: string }>()
  codeReposExistStatus = new Status<{ organizationId: string }, { organizationId: string, status: number }>()
  codeRepoTreesSyncStatus = new Status<{ organizationId: string, codeRepoId: string }, { organizationId: string, codeRepoId: string }>()
  codeRepoRefTreeFindStatus = new Status<void, { organizationId: string, codeRepoId: string, refId: string }>()

  @Mutation
  setCodeRepos (codeRepos: CodeRepo[]) {
    this.codeRepos = codeRepos
  }

  @Mutation
  setCodeRepo (codeRepo: CodeRepo) {
    this.codeRepos = this.codeRepos.map(o => o.id === codeRepo.id ? codeRepo : o)
  }

  @Mutation
  setCodeRepoRefTree (codeRepoRefTree: CodeRepoTreeItem[]) {
    this.codeRepoRefTree = codeRepoRefTree
  }

  @Mutation
  setCodeReposListStatus (...params: Parameters<typeof this.codeReposListStatus.set>) {
    this.codeReposListStatus.set(...params)
  }

  @Mutation
  setCodeReposExistStatus (...params: Parameters<typeof this.codeReposExistStatus.set>) {
    this.codeReposExistStatus.set(...params)
  }

  @Mutation
  setCodeRepoTreesSyncStatus (...params: Parameters<typeof this.codeRepoTreesSyncStatus.set>) {
    this.codeRepoTreesSyncStatus.set(...params)
  }

  @Mutation
  setCodeRepoRefTreeFindStatus (...params: Parameters<typeof this.codeRepoRefTreeFindStatus.set>) {
    this.codeRepoRefTreeFindStatus.set(...params)
  }

  @Action({ rawError: true })
  async codeReposList (organizationId: string) {
    try {
      this.context.commit('setCodeReposListStatus', Status.loading({
        initial: this.codeReposListStatus.idle
      }))

      const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
      const { codeRepos } = await CodeReposService.codeReposList(authorization, organizationId)
      this.context.commit('setCodeRepos', codeRepos)

      this.context.commit('setCodeReposListStatus', Status.success({
        organizationId
      }))

      return codeRepos
    } catch (err: any) {
      const message = err.body?.message || err.message
      this.context.commit('setCodeReposListStatus', Status.error(message))
      this.context.commit('alert/pushAlert', { color: 'error', message }, { root: true })
      throw err
    }
  }

  @Action({ rawError: true })
  async codeReposExist (organizationId: string) {
    try {
      this.context.commit('setCodeReposExistStatus', Status.loading({
        organizationId
      }))

      const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
      try {
        await CodeReposService.codeReposExist(authorization, organizationId)

        this.context.commit('setCodeReposExistStatus', Status.success({
          organizationId,
          status: 200
        }))

        return true
      } catch (err: any) {
        this.context.commit('setCodeReposExistStatus', Status.success({
          organizationId,
          status: err.status
        }))

        return false
      }
    } catch (err: any) {
      const message = err.body?.message || err.message
      this.context.commit('setCodeReposExistStatus', Status.error(message))
      this.context.commit('alert/pushAlert', { color: 'error', message }, { root: true })
      throw err
    }
  }

  @Action({ rawError: true })
  async codeRepoTreesSync ({ organizationId, codeRepoId }: { organizationId: string, codeRepoId: string }) {
    try {
      this.context.commit('setCodeRepoTreesSyncStatus', Status.loading({
        codeRepoId,
        organizationId
      }))

      await new Promise(resolve => setTimeout(resolve, 4000))

      const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
      const { codeRepo } = await CodeReposService.codeRepoTreesSync(authorization, organizationId, codeRepoId)
      this.context.commit('setCodeRepo', codeRepo)

      this.context.commit('setCodeRepoTreesSyncStatus', Status.success({
        codeRepoId,
        organizationId
      }))

      return codeRepo
    } catch (err: any) {
      const message = err.body?.message || err.message
      this.context.commit('setCodeRepoTreesSyncStatus', Status.error(message))
      throw err
    }
  }

  @Action({ rawError: true })
  async codeRepoRefTreeFind ({ organizationId, codeRepoId, refId }: { organizationId: string, codeRepoId: string, refId: string }) {
    try {
      this.context.commit('setCodeRepoRefTreeFindStatus', Status.loading())

      const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
      const { codeRepoTree } = await CodeReposService.codeRepoRefTreeFind(authorization, organizationId, codeRepoId, refId)
      this.context.commit('setCodeRepoRefTree', codeRepoTree)

      this.context.commit('setCodeRepoRefTreeFindStatus', Status.success({
        codeRepoId,
        organizationId,
        refId
      }))

      return codeRepoTree
    } catch (err: any) {
      const message = err.body?.message || err.message
      this.context.commit('setCodeRepoRefTreeFindStatus', Status.error(message))
      throw err
    }
  }
}
